export const pixelPlaceholder = [
  {
    value: 10,
    label: '10px',
  },
  {
    value: 20,
    label: '20px',
  },
  {
    value: 30,
    label: '30px',
  },
  {
    value: 40,
    label: '40px',
  },
  {
    value: 50,
    label: '50px',
  },
  {
    value: 60,
    label: '60px',
  },
  {
    value: 70,
    label: '70px',
  },
  {
    value: 80,
    label: '80px',
  },
  {
    value: 90,
    label: '90px',
  },
  {
    value: 100,
    label: '100px',
  },
];

export const PromptOptions = {
  persona: [
    'Account Executive',
    'B2B Copywriter',
    'B2B Event Planner',
    'Business Analyst',
    'Business Development Manager',
    'Channel Sales Manager',
    'Content Marketing Specialist',
    'CRM Specialist',
    'Customer Success Manager',
    'Data Analyst',
    'Demand Generation Manager',
    'Digital Marketing Specialist',
    'Marketer',
    'Marketing Automation Specialist',
    'Marketing Manager',
    'Market Research Analyst',
    'Partnership Manager',
    'Product Manager',
    'Sales Development Representative',
    'Sales Manager',
    'Sales Operations Manager',
    'Salesperson',
    'SEO Expert',
    'Social Media Manager',
    'Trade Marketing Specialist',
  ],
  who: [
    'analyze sales data for quarterly performance review',
    'arrange a press release for a new product launch',
    'attend a networking event to meet potential partners',
    'conduct a competitor analysis report',
    'conduct a market research survey to identify new opportunities',
    'coordinate a product demo for a prospect',
    'coordinate with influencers or industry experts for a collaborative project',
    'create a one-pager about Accent Connect',
    "create an SEO strategy for the company's website",
    'develop a customer success plan for a new client',
    'develop a sales presentation for potential clients',
    'design a landing page for a new marketing campaign',
    'draft a proposal for a potential business deal',
    'follow up with a prospect',
    'implement tracking metrics for a digital marketing campaign',
    'organize a webinar to demonstrate the latest product features',
    'plan and execute a social media campaign',
    'prepare a report on customer feedback for a specific product',
    'prepare and send out a newsletter to subscribers',
    'review and revise the pricing strategy based on market trends',
    'set up a meeting with a strategic partner',
    'test a new feature in the product before its public release',
    'train the sales team on a new selling technique',
    'update CRM with the latest customer interactions',
    'write a blog about marketing',
    'Write a user story for a new feature',
  ],
  what: [
    'an email that will entice a response',
    'an outline of the the data for my team',
    'a presentation that communicates this idea',
    'a mindmap to help me think through the concept',
    'an email campaign for an outbound campaign',
    'a blog that keeps readers attention and drives them to a CTA',
    'a document that excites the reader about our differentiators',
    'a social media post promoting this',
    'a presentation for a keynote',
    'my idea across to my team',
  ],
  audience: ['a casual reader', 'a technical reader', 'the IT guy', 'a sales persona', 'a marketing persona'],
};

export function valuetext(value) {
  return `${value}°C`;
}

export const placeholders = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
];
export function percenttext(value) {
  return `${value * 100}%`;
}

export const percentplaceholders = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 25,
    label: '25%',
  },
  {
    value: 50,
    label: '50%',
  },
  {
    value: 75,
    label: '75%',
  },
  {
    value: 100,
    label: '100%',
  },
];
