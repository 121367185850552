import { errorMessageHandler } from '../utils/helpers';
import { setSnackBarAlert } from '../redux/slices/AlertsSlice';
import axios from '../utils/axios';

const getAllTools = (signal) => async (dispatch) => {
  try {
    const { data: response } = await axios.get('/api/allTools', {
      ...(signal && { signal }),
    });
    if (response.status && response.data) {
      return {
        customTools: response.data.custom_tools,
        systemTools: response.data.system_tools,
      };
    }
  } catch (error) {
    if (error.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

const handleSystemToolsAction = (id, action) => async (dispatch) => {
  try {
    const isDisabled = action;
    const { data: response } = await axios.post('/api/systemToolsSettings', {
      systemToolId: id,
      isDisabled,
    });
    if (response.status && response.data) {
      dispatch(setSnackBarAlert({ severity: 'success', message: [response?.message] }));
      return response.data;
    }
  } catch (error) {
    if (error.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

const handleSystemToolsName = (id, name) => async (dispatch) => {
  try {
    const { data: response } = await axios.patch(`/api/SystemTools/${id}`, {
      name,
    });
    if (response.status && response.data) {
      dispatch(setSnackBarAlert({ severity: 'success', message: [response?.message] }));
      return response.data;
    }
  } catch (error) {
    if (error.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

const createCustomTool = (name, description, prompt, knowledgebase) => async (dispatch) => {
  try {
    const { data: response } = await axios.post('/api/customTools', {
      name,
      description,
      system_prompt: prompt,
      use_knowledge_base: knowledgebase,
    });
    if (response.status && response.data) {
      dispatch(setSnackBarAlert({ severity: 'success', message: [response?.message] }));
      return response.data;
    }
    dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(response) }));
  } catch (error) {
    if (error.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

const updateCustomTool = (id, name, description, prompt, knowledgebase) => async (dispatch) => {
  try {
    const { data: response } = await axios.patch(`/api/customTools/${id}`, {
      name,
      description,
      system_prompt: prompt,
      use_knowledge_base: knowledgebase,
    });
    if (response.status && response.data) {
      dispatch(setSnackBarAlert({ severity: 'success', message: [response?.message] }));
      return response.data;
    }
    dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(response) }));
  } catch (error) {
    if (error.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

const getCustomToolById = (id, signal) => async (dispatch) => {
  try {
    const { data: response } = await axios.get(`/api/customTools/${id}`, {
      ...(signal && { signal }),
    });
    if (response.status && response.data) {
      return {
        name: response.data?.name ?? '',
        description: response.data?.description ?? '',
        customPrompt: response.data?.system_prompt ?? '',
        knowledgebase: response.data?.use_knowledge_base,
      };
    }
    dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(response) }));
  } catch (error) {
    if (error.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

const deleteCustomToolById = (id) => async (dispatch) => {
  try {
    const { data: response } = await axios.delete(`/api/customTools/${id}`);
    if (response.status) {
      dispatch(setSnackBarAlert({ severity: 'success', message: [response?.message] }));
      return true;
    }
    dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(response) }));
  } catch (error) {
    if (error.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

export {
  getAllTools,
  handleSystemToolsAction,
  handleSystemToolsName,
  createCustomTool,
  updateCustomTool,
  getCustomToolById,
  deleteCustomToolById,
};
