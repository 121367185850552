import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  const navigate = useNavigate();

  const accessToken = localStorage.getItem('accessToken');

  useEffect(() => {
    if (!accessToken) {
      navigate('/login');
    }
  }, [navigate, accessToken]);

  return children;
}

export default AuthGuard;
