import { useRef, useState } from 'react';
import * as Yup from 'yup';
import styled from '@emotion/styled';
import { Formik } from 'formik';
import { get } from 'lodash';
import { spacing } from '@mui/system';
import { PromptOptions } from '../utils/constants';

import {
  Alert as MuiAlert,
  // Button as MuiButton,
  TextField as MuiTextField,
  Autocomplete,
  Grid,
  LoadingButton,
  Typography,
} from '../mui';

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

// const Button = styled(MuiButton)(spacing);

function LearnForm({ onPromptCreate }) {
  const [promptValue, setPromptValue] = useState('');
  const [loader, setLoader] = useState(false);
  const textFieldRef = useRef(null);

  const validationSchema = Yup.object({
    persona: Yup.string().required('Required'),
    who: Yup.string().required('Required'),
    what: Yup.string().required('Required'),
    audience: Yup.string().required('Required'),
  });

  const createPrompt = async (param) => {
    const { values, setSubmitting } = param;
    const output = `I am a ${values.persona} trying to ${values.who}. Can you help me create ${values.what}? Write this for ${values.audience}.`;
    setTimeout(() => {
      setPromptValue(output);
      //   setStatus({ sent: true });
      setSubmitting(false);
    }, [800]);
  };

  const handlePromptCreate = () => {
    const textFieldValue = textFieldRef.current.value;
    navigator.clipboard.writeText(textFieldValue);
    // .then(() => {
    //   console.log('Text copied to clipboard:', textFieldValue);
    // })
    // .catch((error) => {
    //   console.error('Error copying text to clipboard:', error);
    // });
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
      onPromptCreate(promptValue);
    }, [800]);
  };

  const handleSubmit = (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
    createPrompt({
      values,
      resetForm,
      setErrors,
      setStatus,
      setSubmitting,
    });
  };

  const initialValues = {
    persona: null,
    who: null,
    what: null,
    audience: null,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, handleBlur, handleSubmit, setFieldValue, isSubmitting, touched, values, status }) => (
        <>
          {status && status.sent && (
            <Alert severity="success" my={3}>
              Your prompt has been created successfully!
            </Alert>
          )}

          {status &&
            status.sent === false &&
            get(errors, 'submit', []).map((e) => (
              <Alert key={e} severity="error" my={3}>
                {e}
              </Alert>
            ))}

          <form onSubmit={handleSubmit}>
            <Grid container spacing={3} justifyContent="center">
              {/* <Grid item xs={10}>
                <video
                  width="100%"
                  height="400px"
                  controls
                  style={{ objectFit: 'cover' }}
                  poster="https://hub.packtpub.com/wp-content/uploads/2018/07/robot-AI.jpg"
                >
                  <source src="path/to/video.mp4" type="video/mp4" />
                  <source src="path/to/video.webm" type="video/webm" />
                  <track kind="captions" src="" label="No captions available" default />
                  Your browser does not support the video tag.
                </video>
              </Grid> */}
              <Grid item xs={10}>
                <Typography variant="body2">I am an:</Typography>
                <Autocomplete
                  name="persona"
                  id="tags-outlined"
                  options={PromptOptions.persona}
                  getOptionLabel={(option) => option}
                  isOptionEqualToValue={(option, value) => option === value}
                  value={values.persona}
                  // filterSelectedOptions
                  onChange={(e, value) => setFieldValue('persona', value)}
                  onBlur={handleBlur}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      error={Boolean(touched.persona && errors.persona)}
                      helperText={touched.persona && errors.persona}
                      {...params}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={10}>
                <Typography variant="body2">trying to:</Typography>
                <Autocomplete
                  name="who"
                  id="tags-outlined"
                  options={PromptOptions.who}
                  getOptionLabel={(option) => option}
                  isOptionEqualToValue={(option, value) => option === value}
                  value={values.who}
                  // filterSelectedOptions
                  onChange={(e, value) => setFieldValue('who', value)}
                  onBlur={handleBlur}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      error={Boolean(touched.who && errors.who)}
                      helperText={touched.who && errors.who}
                      {...params}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="body2">so that I get:</Typography>
                <Autocomplete
                  name="what"
                  id="tags-outlined"
                  options={PromptOptions.what}
                  getOptionLabel={(option) => option}
                  isOptionEqualToValue={(option, value) => option === value}
                  value={values.what}
                  // filterSelectedOptions
                  onChange={(e, value) => setFieldValue('what', value)}
                  onBlur={handleBlur}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      error={Boolean(touched.what && errors.what)}
                      helperText={touched.what && errors.what}
                      {...params}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={10}>
                <Typography variant="body2">Write it for:</Typography>
                <Autocomplete
                  name="audience"
                  id="tags-outlined"
                  options={PromptOptions.audience}
                  getOptionLabel={(option) => option}
                  isOptionEqualToValue={(option, value) => option === value}
                  value={values.audience}
                  // filterSelectedOptions
                  onChange={(e, value) => setFieldValue('audience', value)}
                  onBlur={handleBlur}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      error={Boolean(touched.audience && errors.audience)}
                      helperText={touched.audience && errors.audience}
                      {...params}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={10}>
                <LoadingButton loading={isSubmitting} fullWidth type="submit" variant="contained">
                  Create My Prompt
                </LoadingButton>
              </Grid>

              <Grid item xs={10}>
                <TextField
                  fullWidth
                  inputRef={textFieldRef}
                  disabled={!promptValue}
                  value={promptValue}
                  onChange={(e) => setPromptValue(e.target.value)}
                  multiline
                  rows={5}
                />
              </Grid>
              <Grid item xs={10}>
                <LoadingButton
                  loading={loader}
                  fullWidth
                  disabled={!promptValue}
                  variant="contained"
                  sx={{
                    textTransform: 'none',
                    bgcolor: 'rgba(16,185,129)',
                    '&:hover': { bgcolor: 'rgba(13, 148, 100)' },
                  }}
                  onClick={handlePromptCreate}
                >
                  Try it out!
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </Formik>
  );
}

export default LearnForm;
