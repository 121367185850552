import { useState, useEffect, useCallback, useContext } from 'react';
import {
  Box,
  ChatBubbleOutlineIcon,
  Stack,
  Typography,
  EditRoundedIcon,
  DeleteIcon,
  CircularProgress,
  LoadingButton,
  TextField,
  CheckIcon,
  CancelIcon,
} from '../../mui';
import axios from '../../utils/axios';
import Snackbar from '../snackbar';
import Scrollbar from '../scrollbar';
import { QueriesHistory } from '../../store/QueriesHistory';

export default function NavSection() {
  const [hoverFieldId, setHoverFieldId] = useState(null);
  const [pageData, setPageData] = useState(null);
  const [editedTitle, setEditedTitle] = useState('');

  const [chatData, setChatData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  const [updateLoader, setUpdateLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [editFieldId, setEditFieldId] = useState(null);
  const [deleteFieldId, setDeleteFieldId] = useState(null);
  const [snackAlert, setSnackAlert] = useState(null);
  const [page, setPage] = useState(1);
  const queryData = useContext(QueriesHistory);
  const chatID = localStorage.getItem('chatId');

  const updateChatTitle = async (id) => {
    setUpdateLoader(true);
    setSnackAlert(null);
    try {
      const payload = { message: editedTitle };
      const { data: response } = await axios.put(`/chat/${id}`, payload);
      if (response.status) {
        setSnackAlert({ severity: 'success', message: response.message });
        await chatHistoryApiHandler();
      } else {
        setSnackAlert({ severity: 'error', message: response.message });
      }
    } catch (err) {
      console.log('err', err);
      setSnackAlert({ severity: 'error', message: 'Something went wrong !' });
    } finally {
      setUpdateLoader(false);
      setEditFieldId(null);
      setDeleteFieldId(null);
    }
  };

  const deleteChatTitle = async (id) => {
    setDeleteLoader(true);
    setSnackAlert(null);
    try {
      const payload = { message: editedTitle };
      const { data: response } = await axios.delete(`/chat/${id}`, payload);
      if (response.status) {
        setSnackAlert({ severity: 'success', message: response.message });
        chatHistoryApiHandler();
        if (id === Number(chatID) || id === queryData.firstChatId) queryData.resetQueryHistory();
      } else {
        setSnackAlert({ severity: 'error', message: response.message });
      }
    } catch (err) {
      console.log('err', err);
      setSnackAlert({ severity: 'error', message: 'Something went wrong !' });
    } finally {
      setEditFieldId(null);
      setDeleteFieldId(null);
      setDeleteLoader(false);
    }
  };

  const chatHistoryApiHandler = useCallback(async () => {
    try {
      const payload = { page: 1, page_size: 20 };
      const { data: response } = await axios.post('/chat', payload);
      if (response.status) {
        setPageData({
          totalPageCount: response?.total_page_count,
          totalCount: response?.total_count,
        });
        setChatData(response.data);
      } else {
        setSnackAlert({ severity: 'error', message: response.message });
      }
    } catch (err) {
      console.log('err', err);
      setSnackAlert({ severity: 'error', message: 'Something went wrong !' });
    } finally {
      setLoader(false);
      setBtnLoader(false);
    }
  }, []);

  const showMoreHandler = async (page) => {
    setPage(page);
    setBtnLoader(true);
    try {
      const payload = { page, page_size: 20 };
      const { data: response } = await axios.post('/chat', payload);
      if (response.status) {
        setPageData({
          totalPageCount: response?.total_page_count,
          totalCount: response?.total_count,
        });
        setChatData((prev) => [...prev, ...response.data]);
      } else {
        setSnackAlert({ severity: 'error', message: response.message });
      }
    } catch (err) {
      console.log('err', err);
      setSnackAlert({ severity: 'error', message: 'Something went wrong !' });
    } finally {
      setBtnLoader(false);
    }
  };

  useEffect(() => {
    chatHistoryApiHandler();
  }, [chatHistoryApiHandler, queryData.chatTitle]);

  return (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box>
        {snackAlert && <Snackbar severity={snackAlert.severity} message={snackAlert.message} />}

        {loader ? (
          <Box display="flex" justifyContent="center" alignItems="center" sx={{ minHeight: '50vh' }}>
            <CircularProgress size="20px" />
          </Box>
        ) : chatData.length ? (
          <>
            {chatData.map((chat, index) => (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                key={index}
                spacing={2}
                onMouseEnter={() => setHoverFieldId(chat.id)}
                onMouseLeave={() => setHoverFieldId(null)}
                sx={{
                  p: '5px 10px',
                  bgcolor:
                    hoverFieldId === chat.id || queryData.contextChatId === chat.id || queryData.firstChatId === chat.id
                      ? 'rgba(124, 124, 124, 0.41)'
                      : '',
                  borderRadius: 1,
                  m: 1,
                }}
              >
                <ChatBubbleOutlineIcon
                  sx={{
                    color:
                      hoverFieldId === chat.id ||
                      queryData.contextChatId === chat.id ||
                      queryData.firstChatId === chat.id
                        ? 'white'
                        : '#a4a4a4',
                    fontSize: 18,
                  }}
                />

                <TextField
                  disabled={editFieldId !== chat.id}
                  variant="standard"
                  value={editFieldId === chat.id ? editedTitle : chat.message}
                  sx={{
                    border: 'none',
                    input: { cursor: 'pointer' },
                    '& .MuiInputBase-input': {
                      textOverflow: 'ellipsis',
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputRef={(input) => input && input.focus()}
                  onChange={(e) => setEditedTitle(e.target.value)}
                  onClick={() => queryData.setChatIdHandler(chat.id)}
                />

                {hoverFieldId === chat.id ? (
                  <>
                    {editFieldId || deleteFieldId === chat.id ? (
                      <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                        {updateLoader || deleteLoader ? (
                          <Box display="flex" justifyContent="center" alignItems="center">
                            <CircularProgress size="15px" />
                          </Box>
                        ) : (
                          <CheckIcon
                            sx={{
                              color: hoverFieldId === chat.id ? 'white' : '#a4a4a4',
                              fontSize: 18,
                              cursor: 'pointer',
                            }}
                            onClick={() => (editFieldId ? updateChatTitle(chat.id) : deleteChatTitle(chat.id))}
                          />
                        )}
                        <CancelIcon
                          sx={{
                            color: hoverFieldId === chat.id ? 'white' : '#a4a4a4',
                            fontSize: 18,
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setEditFieldId(null);
                            setDeleteFieldId(null);
                          }}
                        />
                      </Stack>
                    ) : (
                      <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                        <EditRoundedIcon
                          sx={{
                            color: hoverFieldId === chat.id ? 'white' : '#a4a4a4',
                            fontSize: 18,
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setEditedTitle(chat.message);
                            setEditFieldId(chat.id);
                          }}
                        />
                        <DeleteIcon
                          sx={{
                            color: hoverFieldId === chat.id ? 'white' : '#a4a4a4',
                            fontSize: 18,
                            cursor: 'pointer',
                          }}
                          onClick={() => setDeleteFieldId(chat.id)}
                        />
                      </Stack>
                    )}
                  </>
                ) : null}
              </Stack>
            ))}
            {pageData.totalCount !== chatData.length && (
              <div style={{ textAlign: 'center', marginBottom: 5 }}>
                <LoadingButton loading={btnLoader} size="small" onClick={() => showMoreHandler(page + 1)}>
                  show more
                </LoadingButton>
              </div>
            )}
          </>
        ) : (
          <Typography sx={{ textAlign: 'center' }}>No data found!</Typography>
        )}
      </Box>
    </Scrollbar>
  );
}
