import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  TextField,
} from '../mui';
import { createCustomTool, getCustomToolById, updateCustomTool } from './apiCalls';
import { setActiveRerender, setSearchTitle } from '../redux/slices/MasterSlice';

const CustomToolDialogue = ({ openDialog, setOpenDialog, customToolId, setCustomToolId }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [customPrompt, setCustomPrompt] = useState('');
  const [knowledgebase, setKnowledgebase] = useState(true);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const search = JSON.parse(localStorage.getItem('search'));

  const handleClose = () => {
    setCustomToolId(null);
    setOpenDialog(false);
  };

  const handlePromptCreate = async () => {
    setLoader(true);
    let response = null;
    if (customToolId) {
      response = await dispatch(updateCustomTool(customToolId, name, description, customPrompt, knowledgebase));
      if (response && search.id === customToolId) {
        dispatch(setSearchTitle(name));
      }
    } else {
      response = await dispatch(createCustomTool(name, description, customPrompt, knowledgebase));
    }
    if (response) {
      handleClose();
      dispatch(setActiveRerender());
    }
    setLoader(false);
  };

  useEffect(() => {
    const controller = new AbortController();
    if (customToolId) {
      (async () => {
        setLoader(true);
        const response = await dispatch(getCustomToolById(customToolId, controller.signal));
        if (response) {
          setName(response.name);
          setDescription(response.description);
          setCustomPrompt(response.customPrompt);
          setKnowledgebase(response.knowledgebase);
        }
        setLoader(false);
      })();
    }
    return () => controller.abort();
  }, [dispatch, customToolId]);

  return (
    <Dialog open={openDialog} onClose={handleClose} fullWidth maxWidth="sm" PaperProps={{ style: { height: 500 } }}>
      <DialogContent>
        {!loader ? (
          <Grid container justifyContent="center" alignItems="center" spacing={2} my={1}>
            <Grid item xs={10}>
              <TextField
                value={name ?? ''}
                onChange={(e) => setName(e.target.value.trimStart())}
                label="Title"
                fullWidth
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                value={description ?? ''}
                onChange={(e) => setDescription(e.target.value.trimStart())}
                label="Description"
                fullWidth
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                value={customPrompt ?? ''}
                onChange={(e) => setCustomPrompt(e.target.value.trimStart())}
                label="Prompt"
                fullWidth
                multiline
                rows={7}
              />
            </Grid>
            <Grid item xs={10}>
              <Stack direction="row" justifyContent="space-between" alignItems="center" my={1}>
                <FormControlLabel
                  control={<Switch checked={knowledgebase} onChange={() => setKnowledgebase((prev) => !prev)} />}
                  label="Knowledgebase"
                />
                <Button
                  variant="contained"
                  disabled={!(name && description && customPrompt)}
                  onClick={handlePromptCreate}
                >
                  Save
                </Button>
              </Stack>
            </Grid>
          </Grid>
        ) : (
          <Box display="flex" justifyContent="center" my={20}>
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CustomToolDialogue;
