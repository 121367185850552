import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  settings: null,
};

export const SettingsSlice = createSlice({
  name: 'SettingsSlice',
  initialState,
  reducers: {
    setSettings: (state, action) => {
      state.settings = action.payload;
    },
  },
});

export const { setSettings } = SettingsSlice.actions;

export default SettingsSlice.reducer;
