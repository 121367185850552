import { useEffect, useState } from 'react';
import { Box, Divider, Slider, TextField, Typography } from '@mui/material';
// import LoadingButton from '@mui/lab/LoadingButton';
import toast from 'react-hot-toast';
// import SaveIcon from '@mui/icons-material/Save';
import { useDispatch, useSelector } from 'react-redux';
import { getSettingsData } from '../redux/actions/SettingsActions';
import { Button } from '../mui';
import axios from '../utils/formaxios';
import { defaultSettingsKeys } from '../utils/helpers';
import { percentplaceholders, percenttext, placeholders, valuetext } from '../utils/constants';
// import FilePicker from '../components/file-picker/FilePicker';

// const lightButton = {
//   borderRadius: '6px',
//   padding: '8px 16px',
//   cursor: 'pointer',
//   transition: 'all 0.2s',
//   textDecoration: 'none',
//   color: 'white',
//   backgroundColor: '#2065D1',
//   '&:hover': {
//     backgroundColor: '#3d1bce',
//   },
// };
// const darkButton = {
//   borderRadius: '6px',
//   padding: '8px 16px',
//   cursor: 'pointer',
//   transition: 'all 0.2s',
//   textDecoration: 'none',
//   color: 'white',
//   backgroundColor: '#1B75BC',
//   '&:hover': {
//     backgroundColor: '#3d1bce',
//   },
// };

const lightInput = {
  borderColor: '#a4a4a4',
  mt: 3,
  borderRadius: '10px',
  marginTop: '10px',
  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  backgroundColor: '#ffffff',
};

const darkInput = {
  borderColor: '#a4a4a4',
  mt: 3,
  borderRadius: '10px',
  marginTop: '10px',
  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  backgroundColor: '#282828',
};

const AdminPage = ({ handleClose }) => {
  const [source, setSource] = useState(null);
  const [score, setScore] = useState(null);
  const [description, setDescription] = useState(null);
  const [logo, setLogo] = useState(null);
  const [chatLogo, setChatLogo] = useState(null);
  // const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const updateSettings = async (body) => {
    // setLoading(true);
    try {
      const { data: response } = await axios.patch(`/users/settings/update`, body, {
        'Content-Type': 'application/json',
      });
      if (response.status) {
        // eslint-disable-next-line array-callback-return
        toast.success(response.message);
        // getSettings();
        dispatch(getSettingsData());
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      toast.error('Something Went Wrong!');
    } finally {
      // setLoading(false);
    }
  };

  const settings = useSelector((state) => state.settings.settings);

  const getSettings = async () => {
    if (settings) {
      // eslint-disable-next-line array-callback-return
      settings.map((res) => {
        if (res.key === defaultSettingsKeys.SOURCE_LIMIT) setSource(Number(res.value));
        if (res.key === defaultSettingsKeys.SIMILARITY_SCORE) {
          const percentage = Number(res.value) * Number(100);
          setScore(percentage);
        }
        if (res.key === defaultSettingsKeys.PERSONALITY_PROMPT) setDescription(res.value);
        if (res.key === defaultSettingsKeys.LOGO) {
          setLogo({ imageName: res.name, image: res.value, upload: false });
        }
        if (res.key === defaultSettingsKeys.CHAT_LOGO) {
          setChatLogo({ imageName: res.name, image: res.value, upload: false });
        }
      });
    }
  };
  const SubmitForm = (e) => {
    e.preventDefault();
    const scoreSetting = score / 100;
    const bodyFormData = new FormData();
    bodyFormData.append(defaultSettingsKeys.SOURCE_LIMIT, source);
    bodyFormData.append(defaultSettingsKeys.SIMILARITY_SCORE, scoreSetting);
    bodyFormData.append(defaultSettingsKeys.PERSONALITY_PROMPT, description);
    if (logo?.upload) bodyFormData.append(defaultSettingsKeys.LOGO, logo.image, logo.name);
    if (chatLogo?.upload) bodyFormData.append(defaultSettingsKeys.CHAT_LOGO, chatLogo.image, chatLogo.name);
    const Body = JSON.stringify({
      settings: [
        { key: defaultSettingsKeys.SOURCE_LIMIT, value: source },
        { key: defaultSettingsKeys.SIMILARITY_SCORE, value: scoreSetting },
        { key: defaultSettingsKeys.PERSONALITY_PROMPT, value: description },
      ],
    });
    updateSettings(Body);
  };
  useEffect(() => {
    getSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box p={6} component="form" onSubmit={SubmitForm}>
      <Typography variant="h4">Knowledge Base</Typography>
      <Typography variant="body2" pt={3}>
        Max Sources
      </Typography>
      <Typography variant="body2" sx={{ fontSize: '14px' }}>
        The maximum number of knowledge base documents considered per chat
      </Typography>
      <Box component="div" sx={{ px: 4 }}>
        <Slider
          id="sources"
          name="sources"
          value={source ?? source}
          getAriaValueText={valuetext}
          step={1}
          min={1}
          max={5}
          valueLabelDisplay="auto"
          onChange={(e) => setSource(e.target.value)}
          marks={placeholders}
          disabled
        />
      </Box>
      <Typography variant="body2" pt={3}>
        Similarity Score
      </Typography>
      <Typography variant="body2" sx={{ fontSize: '14px' }}>
        How relevant does a knowledge base documents have to be used in the model's response?
      </Typography>
      <Box component="div" sx={{ px: 4 }}>
        <Slider
          id="score"
          name="score"
          value={score ?? score}
          getAriaValueText={percenttext}
          disabled
          onChange={(e) => setScore(e.target.value)}
          valueLabelDisplay="on"
          marks={percentplaceholders}
        />
      </Box>
      {/* <Typography variant="body2" pt={3}>
        Manage Content
      </Typography>
      <Typography variant="body2" sx={{ fontSize: '14px', mb: 3 }}>
        Manage the content used for your Accent Assist model
      </Typography>
      <a
        href="https://lo-n02.accent-technologies.com/webclient/apps/admin/manager.htm?libName=sales2"
        target="_blank"
        rel="noreferrer"
        style={localStorage.getItem('theme') === 'DARK' ? darkButton : lightButton}
      >
        Launch Content Manager
      </a>
      <Divider sx={{ my: 3 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FilePicker file={logo} setFile={setLogo} title="Logo" dimensions="180 X 45" />
        </Grid>
        <Grid item xs={12} md={6}>
          <FilePicker file={chatLogo} setFile={setChatLogo} title="Chat Logo" dimensions="45 X 45" />
        </Grid>
      </Grid> */}
      <Divider sx={{ mt: 3 }} />
      <Typography variant="body2" pt={3} sx={{ fontSize: '14px' }}>
        Personality
      </Typography>
      <Typography variant="body2" pt={3}>
        System Personality
      </Typography>
      <Typography variant="body2" sx={{ fontSize: '14px', mb: 2 }}>
        Describe your perfect AI Assistant
      </Typography>
      <TextField
        id="description"
        name="description"
        value={description ?? ''}
        label="My AI Assistant is..."
        disabled
        onChange={(e) => setDescription(e.target.value)}
        multiline
        minRows={3}
        fullWidth
        px={3}
        sx={localStorage.getItem('theme') === 'DARK' ? darkInput : lightInput}
      />
      <Box display="flex" justifyContent="flex-end" mt={2}>
        {/* <LoadingButton
          loadingPosition="start"
          loading={loading}
          variant="contained"
          sx={{ mr: 2 }}
          type="submit"
          disabled={loading ?? true}
          startIcon={<SaveIcon />}
        >
          Save
        </LoadingButton> */}
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default AdminPage;
