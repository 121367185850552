import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Divider,
  Typography,
  Switch,
  Stack,
  DeleteIcon,
  EditRoundedIcon,
  Grid,
  Button,
  TextField,
  AddIcon,
  IconButton,
  CheckIcon,
  CircularProgress,
  Box,
  CloseIcon,
} from '../mui';
import CustomToolDialogue from './CustomToolDialog';
import { deleteCustomToolById, getAllTools, handleSystemToolsAction, handleSystemToolsName } from './apiCalls';
import { setActiveRerender, setCustomTools, setSearchTitle, setSystemTools } from '../redux/slices/MasterSlice';

const ToolPage = () => {
  const [hoverFieldId, setHoverFieldId] = useState(null);
  const [selectedField, setSelectedField] = useState(null);
  const [editFieldId, setEditFieldId] = useState(null);
  const [editedTitle, setEditedTitle] = useState('');
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  const [dltBtnLoader, setDltBtnLoader] = useState(false);
  const [customToolId, setCustomToolId] = useState(null);

  const dispatch = useDispatch();
  const activeRerender = useSelector((state) => state.Master.activeRerender);
  const systemTools = useSelector((state) => state.Master.systemTools);
  const customTools = useSelector((state) => state.Master.customTools);
  const search = JSON.parse(localStorage.getItem('search'));

  const handleToggle = async (id, action) => {
    // optimistic rendering
    dispatch(
      setSystemTools(
        systemTools.map((item) => {
          if (item.id === id) {
            return { ...item, is_disabled: !item.is_disabled };
          }
          return item;
        })
      )
    );
    const response = await dispatch(handleSystemToolsAction(id, !action));
    if (response) {
      // For Search Title
      if (search.id === id) {
        dispatch(setSearchTitle(''));
        localStorage.removeItem('search');
      }
    }
  };

  const handleNameChange = async (id) => {
    setBtnLoader(true);
    const response = await dispatch(handleSystemToolsName(id, editedTitle));
    if (response) {
      // optimistic rendering
      dispatch(
        setSystemTools(
          systemTools.map((item) => {
            if (item.id === id) {
              return { ...item, name: editedTitle };
            }
            return item;
          })
        )
      );
      // For Search Title
      if (search.id === id) dispatch(setSearchTitle(editedTitle));
    }
    setEditFieldId(null);
    setBtnLoader(false);
  };

  const editPersonality = async (e, id) => {
    e.stopPropagation();
    setCustomToolId(id);
  };

  const deletePersonality = async (e, id) => {
    e.stopPropagation();
    setDltBtnLoader(true);
    const response = await dispatch(deleteCustomToolById(id));
    if (response) {
      dispatch(setActiveRerender());
      if (search.id === id) {
        dispatch(setSearchTitle(''));
        localStorage.removeItem('search');
      }
    }
    setDltBtnLoader(false);
  };

  useEffect(() => {
    if (customToolId) setOpen(true);
  }, [customToolId]);

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      const response = await dispatch(getAllTools(controller.signal));
      if (response) {
        dispatch(setCustomTools(response.customTools));
        dispatch(setSystemTools(response.systemTools));
      }
      setLoader(false);
    })();
    return () => controller.abort();
  }, [dispatch, activeRerender]);

  return !loader ? (
    <Grid container justifyContent="center" alignItems="center" spacing={3} my={4} mb={12}>
      <Grid item xs={10}>
        <Typography variant="h6">System Tools</Typography>
      </Grid>
      <Grid item xs={10}>
        {systemTools.map((item) => (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            key={item.id}
            spacing={2}
            sx={{
              p: '10px',
              height: 60,
              bgcolor: '#f0f0f0',
              border: 'solid 1px #f0f0f0',
              borderRadius: 1,
              cursor: 'pointer',
              m: 1,
            }}
          >
            {editFieldId === item.id ? (
              <TextField
                fullWidth
                variant="standard"
                value={editFieldId === item.id ? editedTitle : item?.name}
                sx={{
                  border: 'none',
                  input: { cursor: 'pointer' },
                  '& .MuiInputBase-input': {
                    textOverflow: 'ellipsis',
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                }}
                inputRef={(input) => input && input.focus()}
                onChange={(e) => setEditedTitle(e.target.value)}
              />
            ) : (
              <Typography
                variant="body1"
                sx={{
                  color: '#7e7e7e',
                  width: '100%',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {item?.name}
              </Typography>
            )}
            {!item.is_editable ? (
              <Switch checked={!item.is_disabled} onChange={() => handleToggle(item.id, item.is_disabled)} />
            ) : editFieldId === item.id ? (
              <>
                {btnLoader ? (
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress size="18px" />
                  </Box>
                ) : (
                  <>
                    <IconButton onClick={() => setEditFieldId(null)}>
                      <CloseIcon />
                    </IconButton>
                    <IconButton onClick={() => handleNameChange(item.id)}>
                      <CheckIcon />
                    </IconButton>
                  </>
                )}
              </>
            ) : // <IconButton
            //   onClick={() => {
            //     setEditFieldId(item.id);
            //     setEditedTitle(item?.name);
            //   }}
            // >
            //   {/* <EditRoundedIcon /> */}
            // </IconButton>
            null }
          </Stack>
        ))}
      </Grid>
      <Grid item xs={10}>
        <Divider />
      </Grid>
      <Grid item xs={10}>
        <Typography component="span" variant="h6">
          Custom Tools
        </Typography>
        <Button variant="contained" sx={{ float: 'right' }} onClick={() => setOpen(true)} endIcon={<AddIcon />}>
          Add
        </Button>
      </Grid>
      <Grid item xs={10}>
        {customTools.map((item) => (
          <Box key={item.id}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              onClick={() => setSelectedField((value) => (value === item.id ? null : item.id))}
              onMouseEnter={() => setHoverFieldId(item.id)}
              onMouseLeave={() => setHoverFieldId(null)}
              sx={{
                p: '15px',
                bgcolor: hoverFieldId === item.id ? '#7e7e7e' : '#f0f0f0',
                border: 'solid 1px #f0f0f0',
                borderRadius: 1,
                cursor: 'pointer',
                m: 1,
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: hoverFieldId === item.id ? 'white' : '#7e7e7e',
                  width: '100%',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {item.name}
              </Typography>
              {hoverFieldId === item.id && (
                <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                  <EditRoundedIcon
                    sx={{
                      color: hoverFieldId === item.id ? 'white' : '#a4a4a4',
                      fontSize: 18,
                      cursor: 'pointer',
                    }}
                    onClick={(e) => editPersonality(e, item.id)}
                  />
                  {dltBtnLoader ? (
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <CircularProgress size="18px" />
                    </Box>
                  ) : (
                    <DeleteIcon
                      sx={{
                        color: hoverFieldId === item.id ? 'white' : '#a4a4a4',
                        fontSize: 18,
                        cursor: 'pointer',
                      }}
                      onClick={(e) => deletePersonality(e, item.id)}
                    />
                  )}
                </Stack>
              )}
            </Stack>
            {selectedField === item.id && (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                onClick={() => setSelectedField(null)}
                sx={{
                  p: '15px',
                  cursor: 'pointer',
                  m: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: '#7e7e7e',
                    width: '100%',
                  }}
                >
                  {item.description}
                </Typography>
              </Stack>
            )}
          </Box>
        ))}
      </Grid>
      <Divider sx={{ my: 2 }} />
      {open && (
        <CustomToolDialogue
          customToolId={customToolId}
          setCustomToolId={setCustomToolId}
          openDialog={open}
          setOpenDialog={setOpen}
        />
      )}
    </Grid>
  ) : (
    <Box display="flex" justifyContent="center" my={20}>
      <CircularProgress />
    </Box>
  );
};

export default ToolPage;
