import { useState } from 'react';
import { Box, Dialog, DialogContent, Grid } from '@mui/material';
import LearnForm from './LearnForm';
import Scrollbar from '../components/scrollbar/Scrollbar';
import LearnsToggleSection from './LearnToggleSection';
import { LearnsEnum } from '../utils/helpers';
import ExamplesPromptForm from './ExamplesPromptForm';

export default function LearnDialog(props) {
  const { openDialog, setOpenDialog, onPromptCreate } = props;
  const [openedStack, setOpenedStack] = useState(LearnsEnum.examples);

  const handleClose = () => {
    setOpenDialog(null);
  };

  return (
    <Dialog
      maxWidth="lg"
      PaperProps={{ style: { height: '90%', maxHeight: 800 } }}
      onClose={handleClose}
      open={openDialog}
      fullWidth
    >
      <DialogContent sx={{ padding: 0, m: 0, overflow: 'hidden' }}>
        <Grid container sx={{ height: '100%' }}>
          <Grid item xs={4} sx={{ backgroundColor: '#F3F3F3' }}>
            <LearnsToggleSection opened={openedStack} setOpened={setOpenedStack} />
          </Grid>
          <Grid item xs={8} sx={{ height: '100%', overflowY: 'auto' }}>
            <Scrollbar>
              {openedStack === LearnsEnum.examples ? (
                <ExamplesPromptForm />
              ) : openedStack === LearnsEnum.builder ? (
                <Box sx={{ width: '100%', typography: 'body1', my: 8 }}>
                  <LearnForm onPromptCreate={onPromptCreate} />
                </Box>
              ) : null}
            </Scrollbar>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
