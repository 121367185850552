import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledToggleButtonGroup = styled(Button)(({ theme }) => ({
  background: 'transparent',
  width: '100%',
  color: theme.palette.text.primary,
  alignItems: 'start',
  justifyContent: 'start',
  '&:hover': {
    backgroundColor: 'rgba(124, 124, 124, 0.41)',
  },
  '&:.active': {
    backgroundColor: 'rgba(124, 124, 124, 0.41)',
  },
}));
