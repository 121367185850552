const errorMessageHandler = (responseData) => {
  const errorArray = [];
  if (responseData?.errors?.length) {
    responseData.errors.map((e) => errorArray.push(e.message));
  } else if (responseData?.message) {
    errorArray.push(responseData.message);
  } else {
    errorArray.push('Something went wrong!');
  }
  return errorArray;
};

const settingsEnum = {
  appearance: 'appearance',
  tools: 'tools',
  admin: 'admin',
};

const LearnsEnum = {
  examples: 'example_prompts',
  builder: 'prompt_builder',
};
export const defaultSettingsKeys = {
  SOURCE_LIMIT: 'SOURCE_LIMIT',
  SIMILARITY_SCORE: 'SIMILARITY_SCORE',
  PERSONALITY_PROMPT: 'PERSONALITY_PROMPT',
  LOGO: 'LOGO',
  CHAT_LOGO: 'CHAT_LOGO',
};
export const themeKeys = {
  dark: 'DARK',
  light: 'LIGHT',
};

export { errorMessageHandler, settingsEnum, LearnsEnum };
