import React, { useEffect, useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import { Slider } from '@mui/material';
// import { toast } from 'react-hot-toast';
import { Box, Button, CheckCircleRoundedIcon, Typography, LoadingButton } from '../mui';
import { themeKeys } from '../utils/helpers';
// import axios from '../utils/axios';
import { pixelPlaceholder } from '../utils/constants';

const ApparancePage = ({ activeTheme, activeStyles, notactive, handleClose, setTheme, textSize, setTextSize }) => {
  const font = localStorage.getItem('fontSize');
  useEffect(() => {
    if (!font) {
      localStorage.setItem('fontSize', 16);
    }
  }, [font]);
  const [fontSize, setFontSize] = useState(textSize);
  const [
    loading,
    // setLoading
  ] = useState(false);
  const updateSettings = async () => {
    // setLoading(true);
    // try {
    //   const { data: response } = await axios.patch(`users/settings/font`, body);
    //   if (response.status) {
    //     // eslint-disable-next-line array-callback-return
    //     toast.success(response.message);
    //   } else {
    //     toast.error(response.message);
    //   }
    // } catch (err) {
    //   toast.error('Something Went Wrong!');
    // } finally {
    //   setLoading(false);
    // }
    handleClose();
  };
  const SubmitForm = (e) => {
    e.preventDefault();
    // setLoading(true);
    const body = JSON.stringify({
      font_size: fontSize,
    });
    updateSettings(body);
    setTextSize(fontSize);
    localStorage.setItem('fontSize', fontSize);
  };
  return (
    <Box component="div" display="flex" flexWrap="wrap" justifyContent="center" alignItems="center" sx={{ py: 8 }}>
      <Box style={{ width: '40%', margin: '10px', position: 'relative' }} onClick={() => setTheme(themeKeys.light)}>
        <img
          src="assets/lightmode.png"
          alt="lightUI"
          style={activeTheme === themeKeys.light ? activeStyles : notactive}
        />
        {activeTheme === themeKeys.light ? (
          <CheckCircleRoundedIcon sx={{ position: 'absolute', bottom: 13, right: -12, color: '#2065D1' }} />
        ) : null}
        Light
      </Box>
      <Box style={{ width: '40%', margin: '10px', position: 'relative' }} onClick={() => setTheme(themeKeys.dark)}>
        <img src="assets/darkmode.png" alt="DarkUI" style={activeTheme === themeKeys.dark ? activeStyles : notactive} />
        {activeTheme === themeKeys.dark ? (
          <CheckCircleRoundedIcon sx={{ position: 'absolute', bottom: 13, right: -12, color: '#2065D1' }} />
        ) : null}
        Dark
      </Box>
      <Box p={6} pb={0} component="form" width="100%" onSubmit={SubmitForm}>
        <Typography component="p">Font Size</Typography>
        <Typography component="p" sx={{ fontSize: '14px', pt: 2 }}>
          Adjust Font Size:
        </Typography>
        <Box component="div" sx={{ px: 4, pt: 2 }}>
          <Slider
            id="Pixels"
            name="Pixels"
            value={fontSize}
            valueLabelDisplay="on"
            min={10}
            max={30}
            onChange={(e) => setFontSize(e.target.value)}
            marks={pixelPlaceholder}
          />
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <LoadingButton
            loadingPosition="start"
            loading={loading}
            variant="contained"
            sx={{ mr: 2 }}
            type="submit"
            disabled={loading}
            startIcon={<SaveIcon />}
          >
            Save
          </LoadingButton>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ApparancePage;
