import { settingsEnum } from '../../utils/helpers';
import { Box, Stack, Typography } from '../../mui';
import Scrollbar from '../scrollbar';
import { StyledToggleButtonGroup } from './styles';

export default function SettingsToggleSection({ setOpened, opened }) {
  return (
    <Box sx={{ height: { xs: 'auto', md: '100%' } }}>
      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
        }}
      >
        <>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{
              p: '5px 10px',
              borderRadius: 2,
              m: 1,
            }}
          >
            <Typography sx={{ fontSize: '1.125rem', textAlign: 'start', width: '100%' }} mb={3} px={'6px'}>
              Settings
            </Typography>
            <StyledToggleButtonGroup
              style={
                opened === settingsEnum.appearance
                  ? { marginTop: 4, backgroundColor: 'rgba(124, 124, 124, 0.41)' }
                  : { marginTop: 4 }
              }
              onClick={() => setOpened(settingsEnum.appearance)}
            >
              Appearance
            </StyledToggleButtonGroup>
            <StyledToggleButtonGroup
              style={
                opened === settingsEnum.tools
                  ? { marginTop: 4, backgroundColor: 'rgba(124, 124, 124, 0.41)' }
                  : { marginTop: 4 }
              }
              onClick={() => setOpened(settingsEnum.tools)}
            >
              Tools
            </StyledToggleButtonGroup>
            <StyledToggleButtonGroup
              style={
                opened === settingsEnum.admin
                  ? { marginTop: 4, backgroundColor: 'rgba(124, 124, 124, 0.41)' }
                  : { marginTop: 4 }
              }
              onClick={() => setOpened(settingsEnum.admin)}
            >
              Admin
            </StyledToggleButtonGroup>
          </Stack>
        </>
      </Scrollbar>
    </Box>
  );
}
