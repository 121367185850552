import axios from '../../utils/axios';
import { setSettings } from '../slices/SettingsSlice';
import { appConfig } from '../../config';
import { defaultSettingsKeys } from '../../utils/helpers';

const getCamelCaseData = (data) => {
  if (data.key === defaultSettingsKeys.LOGO || data.key === defaultSettingsKeys.CHAT_LOGO) {
    return {
      key: data.key,
      name: data.value.split('/')[2],
      value: `${appConfig.API_URL}${data.value}`,
    };
  }
  return {
    key: data.key,
    value: data.value,
  };
};

export const getSettingsData = () => async (dispatch) => {
  try {
    const { data: response } = await axios.get(`users/settings/get`);
    if (response?.status) {
      const newData = response.data.map((res) => getCamelCaseData(res));
      dispatch(setSettings(newData));
    } else {
      dispatch(setSettings(null));
      return false;
    }
    return true;
  } catch (error) {
    return [];
  }
};
