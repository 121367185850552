import { LearnsEnum } from '../utils/helpers';
import { Box, Stack, Typography } from '../mui';
import Scrollbar from '../components/scrollbar';
import { StyledToggleButtonGroup } from '../components/toggle-buttons/styles';

export default function LearnsToggleSection({ setOpened, opened }) {
  return (
    <Box sx={{ height: '100%' }}>
      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
        }}
      >
        <>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{
              p: '5px 10px',
              borderRadius: 2,
              m: 1,
            }}
          >
            <Typography sx={{ fontSize: '1.125rem', textAlign: 'start', width: '100%' }} mb={3} px={'6px'}>
              Learn
            </Typography>
            <StyledToggleButtonGroup
              style={
                opened === LearnsEnum.examples
                  ? { marginTop: 4, backgroundColor: 'rgba(124, 124, 124, 0.41)' }
                  : { marginTop: 4 }
              }
              onClick={() => setOpened(LearnsEnum.examples)}
            >
              Example Prompts
            </StyledToggleButtonGroup>
            <StyledToggleButtonGroup
              style={
                opened === LearnsEnum.builder
                  ? { marginTop: 4, backgroundColor: 'rgba(124, 124, 124, 0.41)' }
                  : { marginTop: 4 }
              }
              onClick={() => setOpened(LearnsEnum.builder)}
            >
              Prompt Builder
            </StyledToggleButtonGroup>
          </Stack>
        </>
      </Scrollbar>
    </Box>
  );
}
