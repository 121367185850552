import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  systemTools: [],
  customTools: [],
  activeRerender: false,
  searchTitle: JSON.parse(localStorage.getItem('search'))?.name ?? '',
};

export const MasterSlice = createSlice({
  name: 'MasterSlice',
  initialState,
  reducers: {
    setSystemTools: (state, action) => {
      state.systemTools = action.payload;
    },
    setCustomTools: (state, action) => {
      state.customTools = action.payload;
    },
    setActiveRerender: (state) => {
      state.activeRerender = !state.activeRerender;
    },
    setSearchTitle: (state, action) => {
      state.searchTitle = action.payload;
    },
  },
});

export const { setActiveRerender, setSystemTools, setCustomTools, setSearchTitle } = MasterSlice.actions;

export default MasterSlice.reducer;
